import { Button } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import { phoneHref } from "../text/constants";
import { DescriptionText } from "./DescriptionText";
import SectionHeaderContainer, { SectionHeaderH1Tag } from "./SectionTitle";

const ServicesBody = (props: { headerText: string; descriptionText: string }) => {
  const { headerText, descriptionText } = props;
  return (
    <>
      <Row
        justify="center"
        style={{
          paddingTop: 64,
          paddingBottom: 64,
          paddingLeft: 64,
          paddingRight: 64,
        }}
      >
        <Col span={24}>
          <SectionHeaderContainer>
            <SectionHeaderH1Tag headerText={headerText} />
          </SectionHeaderContainer>
        </Col>

        <Col span={24}>
          {/* <HeaderText headerText={headerText} /> */}
          <DescriptionText descriptionText={descriptionText} />
        </Col>

        <Row
          //unclear why row needed, but only way to get button centered
          style={{
            paddingTop: 20,
          }}
        >
          <Col
            span={24}
            style={{
              color: "black",
              borderColor: "black",
              borderRadius: 0,
              letterSpacing: "4px",
              fontSize: "14px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "black",
                borderColor: "black",
                borderRadius: 0,
                letterSpacing: "4px",
                fontSize: "14px",
              }}
            >
              <a
                //when a user presses, open the client phone app
                href={phoneHref}
              >
                Work with Us
              </a>
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ServicesBody;
