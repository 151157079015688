import React, { useRef } from "react";
import { quickLinksType } from "../componentsxx/Footer";
import Layout from "../componentsxx/Layout";
import { scrollToComponent } from "../componentsxx/LinkElement";
import { navMenuDataT } from "../componentsxx/NavMenu";
import { pageStyles } from "../styles/componentStyles";
import { textObj } from "../text/text";

//use React.FunctionComponent<MyProps> if you need to pass props
interface LayoutDefaultPropsT {
  children?: React.ReactNode;
}

//Used by every individual Services page
const LayoutDefault: React.FC<LayoutDefaultPropsT> = (props) => {
  //Header Button:
  const contactUsRef = useRef(null); //init refs
  const contactUsOnClick = () => scrollToComponent(contactUsRef);
  const headerButton: navMenuDataT["headerButton"] = {
    headerText: textObj.navMenu.headerButton.headerText,
    onClick: contactUsOnClick,
    ref: contactUsRef,
  };
  //Non-Header Buttons:
  const homeRef = useRef(null);
  //const homeOnClick = () => scrollToComponent(homeRef);

  const menuOptsWithOnClick: navMenuDataT["menuOptions"] = [
    {
      text: "Home",
      //onClick: homeOnClick,
      toInternalLink: "/", //homepage is /
    },
  ];
  const footerData: quickLinksType[] = []; //empty quick links

  return (
    <main style={pageStyles}>
      <Layout
        homeRef={homeRef}
        headerButton={headerButton}
        menuOptions={menuOptsWithOnClick}
        footerData={footerData}
        contactUsRef={contactUsRef}
      >
        {props.children}
      </Layout>
    </main>
  );
};

export default LayoutDefault;
